import {Component, OnInit} from '@angular/core';
import {Globals} from "../../../sharedComponents/global-variables/globals";
import {ModalService} from "../../../services/modal/modal.service";
import {BenefitApplicationService} from "../../../services/benefit-application/benefit-application.service";
import {FormValidator} from "../../../sharedComponents/formValidation/formValidator";
import {Router} from '@angular/router';

@Component({
  selector: 'app-benefit-init',
  templateUrl: './benefit-init.component.html',
  styleUrls: ['./benefit-init.component.css']
})

export class BenefitInitComponent implements OnInit {

  constructor(
    public globals: Globals,
    public service: BenefitApplicationService,
    private modal: ModalService,
    private validator: FormValidator,
    private router: Router
  ) {
    this.getSundaysInPreviousSixtyDays();
    // console.log(this.dates1[0][0]);
    this.datestem.valuetem = this.dates1[0][0];
    this.submitValidator = this.validator.getForm(this.validatorSvc, 'submit');
  }

  submitValidator = undefined;
  validatorSvc = 'BenefitInitValidator';

  dates1: (number|string)[][] = [[]];
  dates: any = [];
  datestem: any = {
    id: 0,
    valuetem: ''
  };
  loadingPage = false;
  benefitInitPage = false;
  processBenefitPage = false;
  processBenefitErrorPage = false;
  systemErrorPage = false;
  createSuccess = false;
  createFailure = false;

  updateModel = {
    gmin: '',
    ucQuestion: '',
    weekEndDate: '',
    employeeUcAmt: '',
    ucResponse: '',
    ucWaitingResponse: '',
    exhaustResponse: '',
    denialResponse: '',
    earningsHolidayPay: '',
    earningsHolidayPayResponse: '',
    earningsPension: '',
    earningsPensionResponse: '',
    earningsGM: '',
    earningsGMResponse: '',
    earningsNonGM: '',
    earningsNonGMResponse: ''
  };

  ucQuestionOne = false;
  ucQuestionTwo = false;
  ucQuestionThree = false;
  ucQuestionFour = false;
  holidayPayFlag = false;
  gmEarningsFlag = false;
  pensionFlag = false;

  DB2FORMATSLASH = 'MM/DD/YYYY';

  ucamount = '';
  ucDollarAmount = '';
  ucCentsAmount = '';
  holidayPayAmount = '';
  gmEarningsAmount = '';
  pensionAmount = '';
  otherEarningsDollarsAmount = '';
  otherEarningsCentsAmount = '';
  ucRadioAnswer = '';
  ucWaitingAnswer = '';
  exhaustedAnswer = '';
  denialAnswer = '';
  holidayPayAnswer = '';
  gmEarningsAnswer = '';
  pensionAnswer = '';
  otherEarningsAnswer = '';

  dayOfWeek = '';
  draftDate = '';
  fundsAvailableDate = '';

  errorMessage = '';
  allErrorMessages = new Map<string, string> ([
    ['901', 'misrepresentation.'],
    ['902', 'waived dependent information - SUB-DI form needed.'],
    ['904', 'U.C. Monetary Determination is under protest.'],
    ['905', 'U.C. Monetary Determination denied, no U.C. eligibility.'],
    ['906', 'non-Monetary Determination disqualified U.C. benefit.'],
    ['907', 'non-Monetary Determination is under protest.'],
    ['908', 'receiving total disability workers compensation award.'],
    ['909', 'a disability date.'],
    ['910', 'weekending date has already been applied for.'],
    ['911', 'no Monetary Determination set up.'],
    ['912', 'you have entered a social security number that is not found to be a GM employee. Please verify your social security number and try again later.'],
    ['913', 'Must apply using a SUB application form.'],
    ['917', 'employee was not laid off when their benefit year began.'],
    ['918', 'employee entered invalid U.C. amount.'],
    ['919', 'a status code problem.'],
    ['920', 'application weekending date is over 60 days old.'],
    ['921', 'pension information needed.'],
    ['922', 'insufficient seniority.'],
    ['923', 'invalid application weekending date.'],
    ['924', 'invalid layoff status code.'],
    ['925', 'Monetary Determination has a discrepancy.'],
    ['926', 'not on a qualifying layoff.'],
    ['927', 'not a SUB covered employee.'],
    ['928', 'Refused a Non-Volunteer Job Offer.'],
    ['929', 'serving a state U.C. waiting week while on a non-volume related layoff.'],
    ['931', 'disability waiting week, first disability period after a non-disability period.'],
    ['932', 'GM or Delphi job offer refusal.'],
    ['933', 'application is older than C-SUB start date.'],
    ['936', 'SUB job offer refusal.'],
    ['937', 'employee reported U.C., but has no U.C. eligibility remaining.'],
    ['938', 'labor dispute.'],
    ['939', 'SUB entitlement has been exhausted.'],
    ['941', 'act of God layoff exceeds 2 weeks.'],
    ['942', 'employee is eligible for independence week pay.'],
    ['943', 'employee is eligible for vacation shutdown pay.'],
    ['947', 'I.S.P. balance remaining.'],
    ['948', 'eligibility remaining under the salaried layoff benefit plan.'],
    ['951', 'auto-SUB information not numeric.'],
    ['952', 'eligible for a partial SUB benefit, must apply with a SUB application form.'],
    ['953', 'eligible for holiday pay.'],
    ['954', 'received zero U.C. from an auto-SUB state.'],
    ['955', 'auto-SUB reported non-GM or non-Delphi earnings without dollar amount.'],
    ['956', 'invalid number of effective days.'],
    ['957', 'must apply using the Interactive Voice Response System or Web.'],
    ['958', 'invalid U.C. Amount.'],
    ['959', 'no SUB eligibility period found.'],
    ['961', 'dependent information not setup for employee.'],
    ['963', 'need Sunday earnings for pairing with U.C. week.'],
    ['966', 'received automatic short work week benefit.'],
    ['967', 'SUB entitlement not set up.'],
    ['968', 'must be processed through auto SUB.'],
    ['969', 'received GM or Delphi earnings.'],
    ['970', 'received a partial disability workers compensation  award, must apply using a paper application form.'],
    ['971', 'received a partial disability benefit, must apply using a paper application form.'],
    ['973', 'application has a zero dollar gross benefit.'],
    ['974', 'incorrect employee personnel data found.  Please call a CISA call center representative.'],
    ['975', 'your pay request is being investigated, please contact a CISA call center representative.'],
    ['976', 'a duplicate pay request exists.'],
    ['977', 'AutoSUB outside earnings does not match payroll.'],
    ['978', 'not eligible for SUB during the July Shutdown.'],
    ['979', 'ineligible, T.R.A. Information is needed.'],
    ['980', 'TSP election not setup.'],
    ['981', 'Work Schedule is missing or invalid.'],
    ['998', 'We are having system problems, please try again later.'],
    ['999', 'We are having system problems.']
  ]);

  allDaysOfWeek = new Map<string, string> ([
    ['1', 'Monday'],
    ['2', 'Tuesday'],
    ['3', 'Wednesday'],
    ['4', 'Thursday'],
    ['5', 'Friday'],
    ['6', 'Saturday'],
    ['7', 'Sunday']
  ]);

  // radio button verbiage
  ucOneRadioVerbiage = [['Y', 'Yes, correct'], ['N', 'No, incorrect']];
  ucTwoRadioVerbiage = [['Y', 'Yes'], ['N', 'No']];
  ucThreeRadioVerbiage = [['Y', 'Yes, Exhausted'], ['N', 'No, Not Exhausted']];
  ucFourRadioVerbiage = [['Y', 'Yes, Denied'], ['N', 'No, Not Denied']];
  holidayPayVerbiage = [['Y', 'Correct'], ['N', 'Incorrect']];
  gmEarningsVerbiage = [['Y', 'Correct'], ['N', 'Incorrect']];
  pensionVerbiage = [['Y', 'Correct'], ['N', 'Incorrect']];
  otherEarningsVerbiage = [['Y', 'Yes'], ['N', 'No']];

  processModel = {
    gmin: '',
    weekEndDate: ''
  };
  processResult = undefined;
  createResult = undefined;

  ngOnInit() {
  }

  getSundaysInPreviousSixtyDays() {
    this.loadingPage = true;
    let now:Date = new Date();
    let today:Date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let prevSunday:Date = new Date(new Date(today).setDate(today.getDate() - today.getDay()));

     const sixtyDaysInMillseconds = 1000 * 60 * 60 * 24 * 60;
    //this is for showing more than 60 days for testing purposes
    // const sixtyDaysInMillseconds = 1000 * 60 * 60 * 24 * 74;
    // const sixtyDaysInMillseconds = 1000 * 60 * 60 * 24 * 400;
    let dates: string[] = [];

    while ((today.getTime() - prevSunday.getTime()) <= sixtyDaysInMillseconds) {
      // dates.push(this.globals.formatDateStrslash(prevSunday, false, false));
      dates.push(this.formatDate(prevSunday));
      prevSunday.setDate(prevSunday.getDate() - 7);
    }

    this.dates = dates.reverse();
    this.dates.forEach((value, index) => {
      this.datestem = {};
      this.datestem.id = index;
      this.datestem.valuetem = value;
      this.dates1[index] = [this.datestem.id, this.datestem.valuetem];
    });
    this.loadingPage = false;
    this.benefitInitPage = true;
  }

  formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [month, day, year].join('/');
  }

  onSubmitWeekendingDate() {
    this.benefitInitPage = false;
    this.loadingPage = true;
    this.processModel = {
      gmin: this.globals.gminval,
      weekEndDate: this.dates[this.datestem.valuetem]
    };

    this.service.processBenefitB1(this.processModel).subscribe(response => {
      this.processResult = response;
      this.loadingPage = false;

      if (this.processResult === undefined) {
        this.systemErrorPage = true;
        return;
      }

      if (this.processResult.returnCode === '000') {
        this.processBenefitPage = true;
      } else if (this.processResult.returnCode === '999' || this.processResult.returnCode === '998') {
        this.systemErrorPage = true;
        return;
      } else {
        this.processBenefitErrorPage = true;
        this.assignErrorMessage(this.processResult.returnCode);
        return;
      }

      this.ucamount = this.processResult.ucAmt;

      switch (this.processResult.ucQuestion) {
        case 'UC1':
          this.ucQuestionOne = true;
          break;
        case 'UC2':
          this.ucQuestionTwo = true;
          break;
        case 'UC3':
          this.ucQuestionThree = true;
          break;
        case 'UC4':
          this.ucQuestionFour = true;
          break;
      }

      // set amounts
      this.holidayPayAmount = this.processResult.earningsHolidayPay;
      this.gmEarningsAmount = this.processResult.earningsGm;
      this.pensionAmount = this.processResult.earningsPension;

      // set flags
      if (this.holidayPayAmount !== null && this.holidayPayAmount !== undefined && this.holidayPayAmount !== '0.00') {
        this.holidayPayFlag = true;
      }

      if (this.gmEarningsAmount !== null && this.gmEarningsAmount !== undefined && this.gmEarningsAmount !== '0.00') {
        this.gmEarningsFlag = true;
      }

      if (this.pensionAmount !== null && this.pensionAmount !== undefined && this.pensionAmount !== '0.00') {
        this.pensionFlag = true;
      }

      this.processResult.weekEndDate = this.processResult.weekEndDate.replace(/-/gi, '/');

    }, err => {
      this.loadingPage = false;
      this.systemErrorPage = true;
    });
  }

  submitSubenefit() {
    // verify data
    if (this.ucQuestionOne) {
      if (this.ucRadioAnswer === null || this.ucRadioAnswer === undefined || this.ucRadioAnswer === '') {
        alert('You must select an answer regarding your unemployment compensation.');
        return;
      }
    }
    else if (this.ucQuestionTwo) {
      if (this.ucWaitingAnswer === null || this.ucWaitingAnswer === undefined || this.ucWaitingAnswer === '') {
        alert('You must select an answer regarding your unemployment compensation.');
        return;
      }
    }
    else if (this.ucQuestionThree) {
      if (this.exhaustedAnswer === null || this.exhaustedAnswer === undefined || this.exhaustedAnswer === '') {
        alert('You must select an answer regarding your unemployment compensation.');
        return;
      }
    }
    else {
      if (this.denialAnswer === null || this.denialAnswer === undefined || this.denialAnswer === '') {
        alert('You must select an answer regarding your unemployment compensation.');
        return;
      }
    }


    if (this.ucQuestionOne) {
      if (this.ucRadioAnswer === 'N') {
        if (this.ucDollarAmount.length === 0 || isNaN(Number(this.ucDollarAmount))) {
          alert('You selected your unemployment compensation amount was not correct, please enter the correct UC amount.');
          return;
        }
        if (this.ucCentsAmount.length === 0 ||  isNaN(Number(this.ucCentsAmount))) {
          alert('You must enter a UC cents amount.');
          return;
        }
        if (this.ucCentsAmount.length < 2) {
          alert('You entered an invalid cents amount, it must be 2 digits.');
          return;
        }
      }
      else {
        if (this.ucDollarAmount.length > 0) {
          alert('You answered yes that you did receive the UC amount, but you entered a dollar amount.  Please review and correct the problem.');
          return;
        }
        if (this.ucCentsAmount.length > 0) {
          alert('You answered yes that you did receive the UC amount, but you entered a cents amount.  Please review and correct the problem.');
          return;
        }
      }
    }

    if (this.holidayPayFlag) {
      if (this.holidayPayAnswer === null || this.holidayPayAnswer === undefined || this.holidayPayAnswer === '') {
        alert('You must answer the questions regarding your holiday pay.');
        return;
      }
    }

    if (this.gmEarningsFlag) {
      if (this.gmEarningsAnswer === null || this.gmEarningsAnswer === undefined || this.gmEarningsAnswer === '') {
        alert('You must answer the questions regarding your GM Earnings pay.');
        return;
      }
    }

    if (this.pensionFlag) {
      if (this.pensionAnswer === null || this.pensionAnswer === undefined || this.pensionAnswer === '') {
        alert('You must answer the questions regarding your Pension pay.');
        return;
      }
    }

    if (this.otherEarningsAnswer === null || this.otherEarningsAnswer === undefined || this.otherEarningsAnswer === '') {
      alert('You must select an answer regarding your Other Earnings Amount.');
      return;
    }

    if (this.otherEarningsAnswer === 'Y') {
      if (this.otherEarningsDollarsAmount.length === 0 || isNaN(Number(this.otherEarningsDollarsAmount))) {
        alert('You answered that you received Other Earnings, please enter the correct Other Earnings amount you received.');
        return;
      }
      if (this.otherEarningsCentsAmount.length === 0 || isNaN(Number(this.otherEarningsCentsAmount))) {
        alert('You must enter the other earnings cents amount.');
        return;
      }
      if (this.otherEarningsCentsAmount.length < 2) {
        alert('You entered an invalid cents amount, it must be 2 digits.');
        return;
      }
    }
    else {
      if (this.otherEarningsDollarsAmount.length > 0) {
        alert('Are you sure, you selected that you did not receive any non-GM Earnings, but you have entered a dollar amount.  Please review and correct the problem.');
        return;
      }
      if (this.otherEarningsCentsAmount.length > 0) {
        alert('Are you sure, you selected that you did not receive any non-GM Earnings, but you have entered a cents amount.  Please review and correct the problem.');
        return;
      }
    }

    // if answers don't require fields to be filled, fill with 0s
    if (this.ucQuestionOne) {
      if (this.ucRadioAnswer === 'Y') {
        this.ucDollarAmount = '0';
        this.ucCentsAmount = '0';
      }
    }
    else {
      this.ucDollarAmount = '0';
      this.ucCentsAmount = '0';
    }

    if (this.otherEarningsAnswer === 'N') {
      this.otherEarningsDollarsAmount = '0';
      this.otherEarningsCentsAmount = '0';
    }

    // if user clicks cancel, go to my layoff benefits
    if (!confirm('By submitting this application, you are certifying that you are able and available for work.  If you agree with this statement, press OK.  If you do not agree with this statement, press CANCEL.')) {
      this.router.navigate(['/mylayoffbenef']);
      return;
    }

    this.processBenefitPage = false;
    this.loadingPage = true;

    this.updateModel = {
      gmin: this.globals.gminval,
      ucQuestion: this.processResult.ucQuestion,
      weekEndDate: this.processResult.weekEndDate,
      employeeUcAmt: this.ucDollarAmount + '.' + this.ucCentsAmount,
      ucResponse: this.ucRadioAnswer,
      ucWaitingResponse: this.ucWaitingAnswer,
      exhaustResponse: this.exhaustedAnswer,
      denialResponse: this.denialAnswer,
      earningsHolidayPay: this.holidayPayAmount,
      earningsHolidayPayResponse: this.holidayPayAnswer,
      earningsPension: this.pensionAmount,
      earningsPensionResponse: this.pensionAnswer,
      earningsGM: this.gmEarningsAmount,
      earningsGMResponse: this.gmEarningsAnswer,
      earningsNonGM: this.otherEarningsDollarsAmount + '.' + this.otherEarningsCentsAmount,
      earningsNonGMResponse: this.otherEarningsAnswer
    };

    if (this.ucQuestionOne) {
      this.updateModel.ucResponse = this.ucRadioAnswer;
    }
    else if (this.ucQuestionTwo) {
      this.updateModel.ucResponse = this.ucWaitingAnswer;
    }
    else if (this.ucQuestionThree) {
      this.updateModel.ucResponse = this.exhaustedAnswer;
    }
    else {
      this.updateModel.ucResponse = this.denialAnswer;
    }

    this.service.updateBenefitB2(this.updateModel).subscribe(response => {
      this.loadingPage = false;
      this.createResult = response;

      if (this.createResult === null || this.createResult === undefined ||
          this.createResult.returnCode === null || this.createResult.returnCode === '') {
        this.systemErrorPage = true;
        return;
      }
      this.createResult.weekEndDate = this.createResult.weekEndDate?.replace(/-/gi, '/');

      switch (this.createResult.returnCode) {
        case '000':
          this.createSuccess = true;
          break;
        case '998':
        case '999':
          this.systemErrorPage = true;
          return;
        default:
          this.createFailure = true;
          this.assignErrorMessage(this.createResult.returnCode);
          return;
      }

      this.draftDate = this.createResult.draftDate.substring(5, 7) + '/' + this.createResult.draftDate.substring(8) + '/' + this.createResult.draftDate.substring(0, 4);
      this.fundsAvailableDate = this.createResult.fundsAvailableDate.substring(5, 7) + '/' + this.createResult.fundsAvailableDate.substring(8) + '/' + this.createResult.fundsAvailableDate.substring(0, 4);

      this.dayOfWeek = this.allDaysOfWeek.get(this.createResult.pendingDraftDateDayOfWeek);


    }, err => {
      this.loadingPage = false;
      this.systemErrorPage = true;
    });
  }

  assignErrorMessage(returnCode) {
    this.errorMessage = this.allErrorMessages.get(returnCode);
  }

  resetPage() {
    this.dates1 = [[]];
    this.dates = [];
    this.datestem = {
      id: 0,
      valuetem: ''
    };
    this.loadingPage = false;
    this.benefitInitPage = false;
    this.processBenefitPage = false;
    this.processBenefitErrorPage = false;
    this.systemErrorPage = false;
    this.createSuccess = false;
    this.ucQuestionOne = false;
    this.ucQuestionTwo = false;
    this.ucQuestionThree = false;
    this.ucQuestionFour = false;
    this.holidayPayFlag = false;
    this.gmEarningsFlag = false;
    this.pensionFlag = false;
    this.ucamount = '';
    this.ucDollarAmount = '';
    this.ucCentsAmount = '';
    this.holidayPayAmount = '';
    this.gmEarningsAmount = '';
    this.pensionAmount = '';
    this.otherEarningsDollarsAmount = '';
    this.otherEarningsCentsAmount = '';
    this.ucRadioAnswer = '';
    this.ucWaitingAnswer = '';
    this.exhaustedAnswer = '';
    this.denialAnswer = '',
    this.holidayPayAnswer = '';
    this.gmEarningsAnswer = '';
    this.pensionAnswer = '';
    this.otherEarningsAnswer = '';
    this.dayOfWeek = '';
    this.draftDate = '';
    this.fundsAvailableDate = '';
    this.errorMessage = '';
    this.createResult = undefined;
    this.processResult = undefined;

    this.updateModel = {
      gmin: '',
      ucQuestion: '',
      weekEndDate: '',
      employeeUcAmt: '',
      ucResponse: '',
      ucWaitingResponse: '',
      exhaustResponse: '',
      denialResponse: '',
      earningsHolidayPay: '',
      earningsHolidayPayResponse: '',
      earningsPension: '',
      earningsPensionResponse: '',
      earningsGM: '',
      earningsGMResponse: '',
      earningsNonGM: '',
      earningsNonGMResponse: ''
    };

    this.getSundaysInPreviousSixtyDays();
    this.datestem.valuetem = this.dates1[0][0];
  }

  getprivacyinfo(){
    this.modal.createModal('Privacy',  {
      message: [this.globals.i18n.messages.privacy.text1, this.globals.i18n.messages.privacy.text2, this.globals.i18n.messages.privacy.text3],
      callback: data => {
        if (data.informative) {
          return;
        } else {

        }
      }
    });
  }
}
