<app-loading *ngIf="loadingPage"></app-loading>
<div *ngIf="globals.gminval !=''">
  <app-screen-message [errors]="screenErrors" [messages]="screenMessages"></app-screen-message>

  <div class="searchContainer">
    <app-expandable  [expandable]="false" [title]="globals.i18n.title.contactus">
      <div  *ngIf=" globals.systemerror !== true && mailsuccess !== true">
<!--      <form class="searchForm">-->
        <div class="container">
          <div class="row">

            <app-textbox-control [labelText]="globals?.i18n.field.label.name" [(value)]="searchModel.name"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('name')"
            ></app-textbox-control>
            <app-textbox-control [labelText]="globals?.i18n.field.label.gmin" [(value)]="searchModel.gmin"
                                 [isButtonDisplayed]="false"
                                 [editable] = "false"
                                 [validator]="searchValidator.addProperty('gmin')"
            ></app-textbox-control>

          </div>
          <div class="row">
            <app-textbox-control [labelText]="globals?.i18n.field.label.streetAddress" [(value)]="searchModel.streetAddress"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('streetAddress')"
            ></app-textbox-control>
            <app-textbox-control [labelText]="globals?.i18n.field.label.city" [(value)]="searchModel.city"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('city')"
            ></app-textbox-control>

          </div>
          <div class="row" >
            <app-textbox-control [labelText]="globals?.i18n.field.label.state" [(value)]="searchModel.state"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('state')"
            ></app-textbox-control>
            <app-textbox-control [labelText]="globals?.i18n.field.label.zip" [(value)]="searchModel.zipcode"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('zipcode')"
            ></app-textbox-control>
          </div>
          <div class="row" >
            <app-textbox-control [labelText]="globals?.i18n.field.label.phone" [(value)]="searchModel.phoneNumber"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('phone')"
            ></app-textbox-control>
            <app-textbox-control [labelText]="globals?.i18n.field.label.email" [(value)]="searchModel.emailAddress"
                                 [isButtonDisplayed]="false"
                                 [validator]="searchValidator.addProperty('email')"
            ></app-textbox-control>
          </div>
          <div class="row centeredFlex">
            <app-screen-message [errors]="errorMessages"></app-screen-message>
          </div>
          <div class="row" >
            <app-textarea
              [label] = "globals?.i18n.field.label.comments"
              [(value)]="searchModel.comment"
              [bigInput]="true"
              [mandatory]="true"
              >
            </app-textarea>
          </div>



        <div class="rowContainer centeredFlex">
          <app-button class="margin" type="primary" value="{{globals.i18n.application.button.submit}}"
                      (click)="emailSubmit()" (keyup.enter)="emailSubmit()"
                      [disabled]="loadingSearch"
          ></app-button>
          <app-button class="margin" type="secondary" value="{{globals.i18n.application.button.reset}}"
                      (click)="clearSearch()"
          ></app-button>
        </div>
        </div>
        <br>
        <br>

<!--      </form>-->
      </div>
      <div  *ngIf=" globals.systemerror === true">
        <app-system-error></app-system-error>
      </div>

      <div  *ngIf=" this.mailsuccess === true">
        <div class="container">
          <div class="row">
        <h4>Your input has successfully been sent. Thank you!</h4>
          </div>
           <div class="row">
          <app-button class="margin" type="primary" value="Return to Home"
                      (click)="returnhome()" (keyup.enter)="returnhome()"
          ></app-button>
          <p>Please use the button to return to the home page.</p>
        </div>
        </div>
      </div>

    </app-expandable>
    </div>
  <app-loading *ngIf="loadingSearch"></app-loading>
</div>
