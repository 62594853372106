import {Component, OnInit, ViewChild} from '@angular/core';
import {Globals} from  '../../../sharedComponents/global-variables/globals';
import {ContactUsService} from '../../../services/my-layoff-benefits/contactUs.service';
import {FormValidator} from  '../../../sharedComponents/formValidation/formValidator';
import {Router} from  '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  loadingPage = false;
  screenErrors = [];
  screenMessages = [];
  searchValidator = undefined;
  validatorSvc = 'ContactUsValidator';
  message:[];
  error:[];
  loadingSearch = false;
  mailsuccess = false;
  commentval ='';
  streetAddress ='';
  searchModel = {
    name: '',
    streetAddress: '',
    city: '',
    state: '',
    zipcode: '',
    phoneNumber: '',
    gmin: this.globals.gminval,
    emailAddress: '',
    comment: ''
     };

  errorMessages = [];

  constructor(public globals: Globals, public service: ContactUsService, private validator: FormValidator, public router: Router) {
    if (this.globals.captchaverify === true &&  this.globals.gminverify=== true && this.globals.gminval !==''){
      this.searchValidator = this.validator.getForm(this.validatorSvc, 'search');
    }
  }
  emailSubmit(){
    this.errorMessages = [];
    if(!this.validator.validateAll(this.searchValidator.allFields)){
      return false;
    }
    if (this.searchModel.gmin === ''){
      return false;
    }
    if (this.searchModel.comment == null || this.searchModel.comment.trim() === '') {
      this.errorMessages.push('Please include a comment/question before submitting.');
      return false;
    }
    this.loadingSearch = true;
    if (this.searchModel.comment !== '' && this.searchModel.comment !== null){
      this.commentval ='';
      this.commentval = this.searchModel.comment;
      this.searchModel.comment = this.commentval.replace(/[^A-Za-z-0-9()./ ]+/g, ' ');
    }
    if (this.searchModel.streetAddress !== '' && this.searchModel.streetAddress !== null){
      this.streetAddress ='';
      this.streetAddress = this.searchModel.streetAddress;
      this.searchModel.streetAddress = this.streetAddress.replace(/[^A-Za-z-0-9()./ ]+/g, ' ');
    }
    this.service.sendContactMail(this.searchModel).subscribe(response => {
        this.loadingSearch = false;
        const success = this.globals.formChecking.checkResponse(response,this.message,this.error);
        if (success){
          this.mailsuccess = true;
        }else {
          this.loadingSearch = false;
          this.globals.systemerror = true;
          this.searchModel.gmin = '';
          // this.globals.gmlogoclick = false;
          this.globals.captchaverify = false;
          this.globals.gminattempt = false;
          this.globals.systemunvailable = false;
          this.globals.gminverify = false;
        }
    },
    error => {
      this.loadingSearch = false;
      this.globals.systemerror = true;
      this.searchModel.gmin = '';
      // this.globals.gmlogoclick = false;
      this.globals.captchaverify = false;
      this.globals.gminattempt = false;
      this.globals.systemunvailable = false;
      this.globals.gminverify = false;
    });

  }

  clearSearch(){
    this.mailsuccess = false;
    this.loadingSearch = false;
    this.searchModel = {
      name: '',
      streetAddress: '',
      city: '',
      state: '',
      zipcode: '',
      phoneNumber: '',
      gmin: this.globals.gminval,
      emailAddress: '',
      comment: ''
    };
    this.errorMessages = [];
  }

  returnhome(){
    this.router.navigate(['']);
  }

  ngOnInit(): void {
  }

}
