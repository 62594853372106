import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from './sharedComponents/global-variables/globals';
import {Router, NavigationEnd} from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // providers: [BaseService]
})
export class AppComponent {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public base: Globals, public router: Router) {
    // Register our custom icons with the materials library
    this.matIconRegistry.addSvgIcon('flag_us', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/flags/us.svg'));
    this.matIconRegistry.addSvgIcon('flag_es', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/flags/es.svg'));
  }

  ngOnInit() {
    // handle special salary forms page
    if (window.location.pathname.substring(1) === 'stateUC') {
      this.router.navigate(['stateUC']);
      return;
    }
    if (window.location.pathname.substring(1) === 'stateUC_11_24') {
      this.router.navigate(['stateUC_11_24']);
      return;
    }

    for (let i = 0; i < this.router.config.length; ++i) {
      // search for non-children route
      if (this.router.config[i].path === window.location.pathname.substring(1)) {
        this.router.navigate(['']);
        return;
      }

      // search for children if under mylayoffbenef
      if (this.router.config[i].path === 'mylayoffbenef' && window.location.pathname.substring(1, 14) === 'mylayoffbenef') {
        for (let j = 0; j < this.router.config[i].children.length; ++j) {
          if (this.router.config[i].children[j].path === window.location.pathname.substring(15)) {
            this.router.navigate(['']);
            return;
          }
        }
      }

      // handle 404 reloading
      if (window.location.pathname.substring(1) === '404') {
        return;
      }
    }

    this.router.navigate(['404']);
  }

}


